<template>
  <v-container class="pl-2 pr-4 pt-1" fluid>
    <v-layout row wrap align-space-around justify-center fill-height>
      <v-flex xs12 lg12 xl12>
        <v-card>
          <titleCard title="Dias de alta demanda" subtitle="Fechas de alta demanda para esta tienda"></titleCard>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              class="elevation-0 order-table"
              color="secondary"
              :disable-initial-sort="true"
              no-data-text="No hay dias de alta demanda ingresados"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPage"
            >
              <template slot="items" slot-scope="props">
                <tr>
                  <td>{{ props.item.date }}</td>
                  <td>
                    <app-status :value="props.item.active"></app-status>
                  </td>
                  <td>
                    <v-icon small class="mr-2" @click="openItem(props.item)">
                      far fa-edit
                    </v-icon>
                    <v-icon small class="mr-2" @click="deleteItem(props.item.id)">
                      fas fa-trash
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialogItem" max-width="320px">
      <v-card>
        <app-title-dialog :title="'Fecha de alta demanda'" :close-action="() => (dialogItem = false)" close>
        </app-title-dialog>
        <v-container class="pa-3" grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field v-model="item.date" label="Fecha" type="date" hide-details></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-switch v-model="item.active" label="Activo" hide-details class="mt-1"> </v-switch>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialogItem = false">
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="saveItem"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn fab dark fixed bottom right color="accent" @click="openItem(null)">
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { MI_STORE } from '../../config'
import titleCard from '../useful/titleCard'
import appStatus from '../useful/status.vue'
import appTitleDialog from '../useful/titleDialog.vue'

export default {
  name: 'StoreDailySale',
  components: { titleCard, appStatus, appTitleDialog },
  data: () => ({
    loading: false,
    dialogItem: false,
    items: [],
    item: {
      id: null,
      date: null,
      active: true
    },
    headers: [
      { text: 'Dia', align: 'left', sortable: false },
      { text: 'Estado', align: 'left', sortable: false },
      { text: '', align: 'right', sortable: false }
    ],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }]
  }),
  mounted() {
    this.getItems()
  },
  methods: {
    async getItems() {
      try {
        const res = await this.$http.get(`${MI_STORE}/alta-demanda`)
        this.items = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async saveItem() {
      try {
        let res = null
        if (this.item.id) {
          res = await this.$http.put(`${MI_STORE}/alta-demanda/${this.item.id}`, this.item)
        } else {
          res = await this.$http.post(`${MI_STORE}/alta-demanda`, this.item)
        }

        if (!res.data.error) {
          this.getItems()
          this.dialogItem = false
        }
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteItem(id) {
      try {
        await this.$http.delete(`${MI_STORE}/alta-demanda/${id}`)
        this.getItems()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    openItem(item) {
      if (item) {
        this.item = item
      } else {
        this.item = {
          id: null,
          date: null,
          active: true
        }
      }
      this.dialogItem = true
    }
  }
}
</script>
